import React from 'react'
import Layout from '../components/Layout'
import Images from "../components/Images"

const galeria = () => {
    return (
        <Layout>
            <Images/>
        </Layout>
    )
}

export default galeria
