import React from 'react'
import Image from 'gatsby-image'
import mayalav01 from '../images/mayalav01.jpg'
import {graphql,useStaticQuery} from 'gatsby'

const Images = () => {
    const data = useStaticQuery(graphql`
    {
      fixed: file(relativePath: {eq: "mayalav01.jpg"}) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      fluid: file(relativePath: {eq: "mayalav01.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return (
        <section className="text-center grid grid-cols-3 justify-center py-12">
            <article className="bg-blue-200 py-8 border border-2 border-blue-600">
                <h2>Img Normal</h2>
                <img src={mayalav01} width="100%" alt="fondo"/>
            </article>
            <article className="bg-blue-200 py-8 border border-2 border-blue-600">
                <h1>Img Blur</h1>
                <Image fixed={data.fixed.childImageSharp.fixed} alt="imagen"/>
            </article>
            <article className="bg-blue-200 py-8 border border-2 border-blue-600">
                <h2>Img SVG</h2>
                <Image fluid={data.fluid.childImageSharp.fluid} alt="imagen"/>
            </article>

        </section>
    )
}
// const data = useStaticQuery(graphql`
//     {
//       fixed: file(relativePath: {eq: "mayalav01.jpg"}) {
//         childImageSharp {
//           fixed {
//             src
//           }
//         }
//       }
//       fluid: file(relativePath: {eq: "mayalav02.jpg"}) {
//         childImageSharp {
//           fluid {
//             src
//           }
//         }
//       }
//     }
//   `)

export default Images
